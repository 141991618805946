<template>
<transition :name="transition">
	<div class="partial partial--pandemic pandemic" :style="pageStyles" :key="content.id">
		<slideshow
			:slideCount="slideCount"
			:sectionLabel="`Beyond the Pandemic`"
			:subsectionLabel="content.author"
			:slideActive="slideActive"
			@slideActive="setSlideActive"
			class="slideshow--pandemic"
			:key="content.id"
			>
			<template v-slot:body>
				<div class="pandemic__wrapper">
					<div class="pandemic__title" v-html="content.title">
						<!-- {{ content.title }} -->
					</div>
					<div class="pandemic__content">
						<div class="pandemic__author">
							<img :src="require('@/assets/pandemic/' + content.authorImg)" class="pandemic__author-img" :alt="content.author" />

							<div>
								<div class="pandemic__author-name">
									{{ content.author }}
								</div>
								<div class="pandemic__author-title" v-html="content.authorTitle"></div>
							</div>
						</div>
						<div class="pandemic__quote">
							&ldquo;{{ content.quote }}&rdquo;
						</div>
					</div>
				</div>
			</template>
		</slideshow>
	</div>
</transition>
</template>

<script>
import Slideshow from '@/components/Slideshow'

export default {
	name: 'PartialPandemic',
	components: {
		Slideshow,
	},
	data: () => ({
		slideActive: 0,
	}),
	computed: {
		slides() {
			const content = require(`@/assets/content/pandemic/filler.json`)
			return content || []
		},
		slideCount() {
			return this.slides.length
		},
		content() {
			return this.slides[this.slideActive]
		},
		pageStyles() {
			let img
			try {
				img = require('@/assets/pandemic/'+ this.content.bgImg)
			} catch (error) {
				console.warn(error)
			}
			return {
				backgroundImage: `url(${img})`
			}
		},
		transition() {
			return 'out-left'
		},
	},
	methods: {
		setSlideActive(active) {
			let dir = active > this.slideActive ? 'right' : 'left'
// 			this.transition = active > this.slideActive ? 'out-left' : 'out-right'
// console.log('setSlideActive', active, this.slideActive, this.transition)
			const slide = this.slides[active]
			this.$router.push({ params: { slide: slide.id, dir, } })
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(val) {
				const { slide } = val.params
				const slideIdx = this.slides.findIndex(s => s.id == slide) || 0
				this.slideActive = slideIdx
			},
		},
	},
}
</script>

<style scoped lang="scss">
.pandemic {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&__wrapper {
		//background-color: rgba(255,255,255,.95);
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		width: 100%;

		@include media-query-min-width('tablet') {
			background-color: rgba(255,255,255,.95);
			@include gutter('padding', 1.5);
		}
	}

	&__title {
		@include fontSize(24px);
		@include gutter('margin-bottom', 1);
		color: color_('text');
		font-family: $font-family-secondary;
		font-weight: bold;
		margin-top: 0;
		//text-transform: uppercase;
		line-height: .9;

		::v-deep span {
			color: color_('primary');
		}

		@include media-query-min-width('tablet') {
			@include fontSize(32px);
			@include gutter('margin-top', .2);
			@include gutter('margin-bottom', 1.25);
			@include gutter('padding-bottom', 1);
			border-bottom: 4px solid color_('primary');
		}
	}

	&__content {
		@include fontSize(18px);
		color: color_('text');
		display: flex;
		flex-direction: column;

		@include media-query-min-width('tablet') {
			flex-direction: row;
		}
	}

	&__quote {
		//@include fontSize(18px);
		line-height: 1.5;

		@include media-query-min-width('tablet') {
			@include fontSize(16px);
		}

		@include media-query-min-width('tablet') {
			@include fontSize(18px);
		}
	}

	&__author {
		line-height: 1.5;
		display: flex;
		align-items: center;
		border-bottom: 4px solid color_('primary');
		@include gutter('margin-bottom', 1.25);
		@include gutter('padding-bottom', 1);

		@include media-query-min-width('tablet') {
			border-bottom: 0;
			text-align: center;
			flex-direction: column;
			@include gutter('padding-left', 1);
			@include gutter('padding-right', 1);
			@include gutter('margin-bottom', 0);
			@include gutter('padding-bottom', 0);
		}

		@include media-query-min-width('desktop') {
			@include gutter('padding-left', 1.5);
			@include gutter('padding-right', 2.25);
		}

		&-img {
			@include gutter('margin-right', .75);
			width: 72px;
			height: auto;

			@include media-query-min-width('tablet') {
				@include gutter('margin-right', 0);
				@include gutter('margin-bottom', .75);
				width: 200px;
			}
		}

		&-name {
			@include fontSize(18px);
			font-weight: bold;
			white-space: nowrap;
		}

		&-title {
			@include fontSize(14px);
			color: rgba(color_('text'), .7);
		}
	}
}
</style>
